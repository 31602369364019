import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, List, SliderValueLabel, Stack, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { QRCodeSVG } from "qrcode.react";
import { Session } from "../models/Session";
import { useState, useEffect, useMemo, useRef } from "react";
import { CapacityDisplayModel } from "../models/CapacityDisplayModel";
import { Dictionary } from "express-serve-static-core";
import { useNavigate } from "react-router";
import { MainPageProps } from "../models/MainPageProps";
import * as signalR from "@microsoft/signalr";
import { MainPageData } from "../models/MainPageData";
import ErrorsModal from "./ErrorsModal";

import type { SearchCertificateResponse } from "../models/SearchCertificateResponse";
import { SearchCertificateParams } from "../models/SearchCertificateParams";
import {CustomError} from "../errors/CustomError";

const capacities: CapacityDisplayModel[] = [
  { id: "F3", name: "F3 - Vuurwerk van categorie F3" },
  { id: "F4", name: "F4 - Vuurwerk van categorie F4" },
  { id: "P2", name: "P2 - Andere pyrotechnische artikelen van categorie P2" },
  { id: "T2", name: "T2 - Pyrotechnische artikelen voor theatergebruik van categorie T2" },
];

const DOC_TYPE_NAME = "org.iso.23220.1.nl.minienw.pyropass";
const serverErrorText = "Service is niet beschikbaar. Probeer het later opnieuw.";
const mobileServerCommunicationErrorText = "Er is iets mis gegaan, probeer het opnieuw.";
const validationErrorText = "De licentie kan niet worden geverifieerd. Neem contact op met de uitgevende partij van de licentie.";
const doesntExistOrNotValidErrorText = "Certificaat niet gevonden.";
  
export default function MainPage() {
  const [session, setSession] = useState<Session | null>(null);
  const [name, setName] = useState<string>("");
  const [kvkNumber, setKvkNumber] = useState<string>("");
  const [capacitiesState, setCapacitiesState] = useState<Dictionary<boolean>>(Object.fromEntries(capacities.map(cap => [cap.id, false])));
  const [certificateNumber, setCertificateNumber] = useState<string>("");
  const [generateClicked, setGenerateClicked] = useState<boolean>(false);
  const [generateLocked, setGenerateLocked] = useState<boolean>(false);
  const [errorsModalOpen, setErrorsModalOpen] = useState<boolean>(false);
  const [useApp, setUseApp] = useState<boolean>(false);
  const [useSearch, setUseSearch] = useState<boolean>(false);
  const [errorsModalBodyText, setErrorsModalBodyText] = useState<string>(serverErrorText);

  let webSocketOrSessionRequestFailed = false;

  const nav = useNavigate();

  const onFieldChange = (fn: (val: any) => void) => (e: any) => fn(e.target.value);
  
  const selectedCapacitiesValidationError = (generateClicked: boolean): boolean => generateClicked && Object.values(capacitiesState).filter((v) => v).length == 0;
  const formValidationError = (generateClicked: boolean): boolean =>
    selectedCapacitiesValidationError(generateClicked);

  const reset = () => {
    setGenerateLocked(false);
    setUseApp(false);
    setUseSearch(false);
    setSession(null);
    setCertificateNumber("");
    licensesHubConnection.stop();
  };

  const licensesHubConnection: signalR.HubConnection = useMemo(() => {
    return new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_BACKEND_URL + "licenses-hub")
      .build();
  }, []);

  const handleOpenErrorsModalWithText = (text: string) => {
    console.log(`Start handling with text "${text}"`);
    setErrorsModalBodyText(text);
    setErrorsModalOpen(true);
  };
  const handleCloseErrorsModal = () => {
    setErrorsModalOpen(false);
  };

  const handleWebSocketOrSessionRequestFailure = () => {
    if (webSocketOrSessionRequestFailed) return;
    webSocketOrSessionRequestFailed = true;

    reset();
    handleOpenErrorsModalWithText(serverErrorText);
  };

  const onUseAppClick = () => {
    setGenerateClicked(true);
    if (formValidationError(true)) return;
    setUseApp(true);
    setUseSearch(false);
    onGenerateClick();
    setCertificateNumber("");
  };
  const onUseSearchClick = () => {
    setGenerateClicked(true);
    if (formValidationError(true)) return;
    setGenerateLocked(true);
    setUseApp(false);
    setUseSearch(true);
  };
  const onGenerateClick = () => {
    setGenerateClicked(true);
    // TODO: generateClicked is not set to true at this moment so I have to pass it through parameters.
    // TODO: it generally doesn't seem good, and there is probably a better way.
    if (formValidationError(true)) return;
    setGenerateLocked(true);

    const selectedCapacities = Object
      .entries(capacitiesState)
      .filter(([_, v]) => v)
      .map(([k, _]) => k);

    const mainPageProps: MainPageProps = {
      name: name,
      kvkNumber: kvkNumber,
      selectedCapacitiesModels: capacities.filter(cap => selectedCapacities.includes(cap.id)),
      certificateNumber: certificateNumber
    };
    console.log("[MainPage] Main page properties:", mainPageProps);

    licensesHubConnection.on("result", (license: any) => {
      const mainPageData: MainPageData = {
        mainPageProps: mainPageProps,
        license: license,
        licenseReceiveDate: new Date(Date.now()),
      };
      onLicenseReceive(mainPageData);
    });

    licensesHubConnection.on("device-server-communication-error", (error: any) => {
      onMobileServerCommunicationError(error);
    });

    const capacitiesQuery: string = selectedCapacities
      .map(cap => `capacities=${cap}`)
      .join("&");

    licensesHubConnection
      .start()
      .then(() => {
        webSocketOrSessionRequestFailed = false;
      })
      .catch((_) => {
        handleWebSocketOrSessionRequestFailure();
      })
      .then(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + `Session?docType=${DOC_TYPE_NAME}&connectionId=${licensesHubConnection.connectionId}&${capacitiesQuery}`)
          .then(r => r.json())
          .then(r => {
            if (!r.status) {
              const s: Session = r as Session;
              setSession(s);
            } else {
              reset();
            }
          })
          .catch((_) => {
            handleWebSocketOrSessionRequestFailure();
          });
      });
  };

  const onLicenseReceive = (mainPageData: MainPageData) => {
    licensesHubConnection.stop();

    if (mainPageData.license == null) {
      reset();
      handleOpenErrorsModalWithText(validationErrorText);
    } else {
      nav("/details", {
        state: {
          mainPageData: mainPageData
        }
      });
    }
  };

  const onMobileServerCommunicationError = (error: any) => {
    licensesHubConnection.stop();
    reset();
    handleOpenErrorsModalWithText(mobileServerCommunicationErrorText);
  };

  useEffect(() => {
    if (session && session.readerEngagement)
      console.log("QR", `mdoc://${session!.readerEngagement}`);
  }, [session]);

  const onCapacitiesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCapacitiesState({
      ...capacitiesState,
      [event.target.name]: event.target.checked,
    });
  };
  
  const search = async (
    { DocumentNumber, Categories, VerifierName, VerifierKvkNumber }: SearchCertificateParams
  ): Promise<SearchCertificateResponse> => {
    if (!DocumentNumber || !Categories) {
      throw new Error("[MainPage][Search] Empty parameter passed.");
    }

    const headers: Headers = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");

    const bodyParameters = {
      DocumentNumber,
      Categories,
      VerifierName,
      VerifierKvkNumber
    };
    const request = new Request(`${process.env.REACT_APP_BACKEND_URL}` + "api/SearchLicense", {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify(bodyParameters)
    });
    return fetch(request).then((response) => {
      if (!response.ok) {
        console.log(`[MainPage][Search] Search request has failed. ${response.status}: ${response.statusText}`);
        if (response.status === 404) {
          throw new CustomError(doesntExistOrNotValidErrorText);
        }
        throw new CustomError(serverErrorText);
      }
      return response.json();
    }).then((data) => { return JSON.parse(data); });
  };

  const onSearchClick = ()  => {
    search({
      DocumentNumber: certificateNumber,
      Categories: Object.entries(capacitiesState).filter(([,value]) => value).map(([key]) => key),
      VerifierName: name,
      VerifierKvkNumber: kvkNumber
    }).then((response) => {
      const mainPageData: MainPageData = {
        // TODO: make reusable
        mainPageProps: {
          name: name,
          kvkNumber: kvkNumber,
          selectedCapacitiesModels: capacities.filter(cap =>
            Object
              .entries(capacitiesState)
              .filter(([_, v]) => v)
              .map(([k, _]) => k)
              .includes(cap.id)),
          certificateNumber: certificateNumber
        },
        license: response,
        licenseReceiveDate: new Date(Date.now()),
      };
      if (mainPageData.license == null) {
        throw new CustomError(validationErrorText);
      } else if (!mainPageData.license.document_is_valid) {
        throw new CustomError(doesntExistOrNotValidErrorText);
      }
      else{
        nav("/details", {
          state: {
            mainPageData
          }
        });
      }
    })
      .catch(error => {
        console.error(error);
        let text = error.message;
        if (!(error instanceof CustomError)) {
          text = serverErrorText;
        }          
        reset();        
        handleOpenErrorsModalWithText(text);
      });
  };
 
  return (
    <Container sx={{ height: "100%" }} maxWidth={false}>
      <Stack direction="row" justifyContent="end" sx={{ padding: "48px 64px 8px 0" }}>
        <img src="/Hobéon SKO logo 2021 RGB.png" width={196} />
      </Stack>
      <Container sx={{ height: "100%", maxWidth: "648px" }} maxWidth={false}>

        <h1 id="headerMain">Controlepagina Pyro-pass</h1>

        <Stack spacing={2}>
          <Typography variant="h2" id="headerRequesterInfo">
            <FormLabel id="labelRequesterInfo"><strong>Gegevens aanvrager</strong></FormLabel>
          </Typography>
          <TextField onChange={onFieldChange(setName)} disabled={generateLocked} id="textName" type="text" label="Naam bedrijf" variant="outlined" />
          <TextField onChange={onFieldChange(setKvkNumber)} disabled={generateLocked} id="textKvkNumber" type="text" label="KvK nummer bedrijf" variant="outlined" />
        </Stack>

        <Divider sx={{ mb: 8, border: "none" }} variant="fullWidth" component="div" />

        <FormControl error={selectedCapacitiesValidationError(generateClicked)} required>
          <Stack justifyContent="space-between" spacing={2}>
            <Typography variant="h2">
              <FormLabel id="labelQuestion"><strong>Welke categorieën wil je controleren?</strong></FormLabel>
            </Typography>
            <FormGroup>
              {
                capacities?.map(cat =>
                  <FormControlLabel sx={{ fontSize: 20 }}
                    control={
                      <Checkbox id={"checkBox_"+cat.id} name={cat.id} checked={capacitiesState[cat.id]} onChange={onCapacitiesChange} disabled={generateLocked} />
                    }
                    label={cat.name}
                  />
                )
              }
            </FormGroup>
          </Stack>
        </FormControl>

        <Divider sx={{ mb: 8, border: "none" }} variant="fullWidth" component="div" />
        
        {
          !useApp && !useSearch &&
        <Stack direction="row">
          <Stack spacing={2} width="50%">
            <Button
              id="buttonUseApp"
              variant="contained"
              onClick={onUseAppClick}
              sx={{ height: 64 }}>
              Gebruik eWallet app
            </Button>
          </Stack>
          <Stack paddingLeft={4} width="50%">
            <Button
              id="buttonUseSearch"
              variant="contained"
              onClick={onUseSearchClick}
              sx={{ height: 64 }}>
              Zoek op Pyro-pass nummer
            </Button>
          </Stack>          
        </Stack>
        }
        {
          useApp &&
        <Stack direction="row">
          <Stack spacing={1} width="60%">
            <Button
              id="buttonReset"
              variant="contained"
              onClick={reset}
              sx={{ height: 64 }}>
              Reset
            </Button>
            <Stack fontSize={16} paddingTop={3}>
              <Typography>Scan de QR code met de Kiwa eWallet</Typography>
              <List>
                <Typography>1. Open de eWallet</Typography>
                <Typography>2. Selecteer het juiste certificaat</Typography>
                <Typography>3. Selecteer de tab “delen”</Typography>
                <Typography>4. Scan de QR code</Typography>
                <Typography>5. Geef toestemming voor het delen</Typography>
              </List>
            </Stack>
          </Stack>          
          <Stack paddingLeft={4} width="40%">            
            {
              session &&
              <QRCodeSVG id="qrCodeSvg" value={`mdoc://${session.readerEngagement}`} size={256}></QRCodeSVG>
            }
          </Stack>
        </Stack>
        }
        {
          useSearch &&
          <div>
            <Stack spacing={2}>
              <TextField 
                required 
                onChange={onFieldChange(setCertificateNumber)} 
                disabled={!useSearch} 
                id="certificateNumber" 
                type="text" 
                label="Pyro-pass nummer" 
                variant="outlined" 
              />
            </Stack>
            <Stack direction="row" paddingTop={3}>
              <Stack width="40%">
                <Button
                  id="buttonResetSearch"
                  variant="contained"
                  onClick={reset}
                  sx={{ height: 64 }}>
                  Reset
                </Button>
              </Stack>
              <Stack width="20%"></Stack>
              <Stack width="40%">
                <Button
                  id="buttonSearch"
                  variant="contained"
                  disabled={!useSearch || !certificateNumber}
                  onClick={onSearchClick}
                  sx={{ height: 64 }}>
                  Zoek
                </Button>
              </Stack>
            </Stack>
          </div>
        }
        <Divider sx={{ mb: 8, border: "none" }} variant="fullWidth" component="div" />
      </Container>

      <ErrorsModal open={errorsModalOpen} handleClose={handleCloseErrorsModal} errorsModalBodyText={errorsModalBodyText} />

    </Container >
  );
}