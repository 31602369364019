import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#058b8c",
    },
    error: {
      main: "#D1111C",
    },
  },
  typography: {
    fontFamily: `"Montserrat", sans-serif`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "unset"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "unset"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: "unset",
          width: "100%"
        }
      }
    }
  }
});

export default theme;